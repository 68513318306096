<template>
  <div>
    <!-- {{ payload.valueInduk }} -->
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">User Management</h3>
            <hr class="m-t-0 m-b-40" />
            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.nip"
                  name="nip"
                  type="text"
                  label="NIP"
                />
              </div>
            </div>
            <!-- {{ editMode }} -->
            <!-- v-if="editMode" -->
            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.nik"
                  name="nik"
                  type="text"
                  label="NIK"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.gelarDepan"
                  name="gelarDepan"
                  type="text"
                  label="Gelar Depan"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.namaAsli"
                  name="namaAsli"
                  required
                  type="text"
                  label="Nama Asli"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.gelarBelakang"
                  name="gelarBelakang"
                  type="text"
                  label="Gelar Belakang"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.username"
                  name="username"
                  required
                  type="text"
                  label="Nama Pengguna (Username)"
                />
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.email"
                  name="email"
                  required
                  type="text"
                  label="Email"
                />
              </div>
            </div> -->
            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.email"
                  name="email"
                  type="text"
                  label="Email"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div
                  :class="[
                    'form-group',
                    { 'has-danger': errors.has('password') },
                    ' row',
                  ]"
                >
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                  >
                    Password
                    <small style="color: red" v-if="!editMode">*</small>
                  </label>
                  <div class="col-md-9 col-xs-9">
                    <input
                      v-model="payload.password"
                      type="password"
                      v-validate="'kemlu_password'"
                      name="password"
                      ref="password"
                      :class="[
                        'form-control',
                        { 'form-control-danger': errors.has('password') },
                      ]"
                    />
                    <small
                      v-if="errors.has('password')"
                      class="form-control-feedback"
                      >{{ errors.first("password") }}</small
                    >
                  </div>
                </div>
              </div>
            </div>
            <hr class="m-t-0 m-b-40" />
            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                    >Admin Meeting Room</label
                  >
                  <div class="col-md-9 col-sm-9">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          v-model="payload.isMeeting"
                          type="radio"
                          value="1"
                          class="form-check-input"
                          name="optradiom"
                        />Ya
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          v-model="payload.isMeeting"
                          type="radio"
                          value="0"
                          class="form-check-input"
                          name="optradiom"
                        />Tidak
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="m-t-0 m-b-40" />
            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                    >Penanda Sekretaris Daerah</label
                  >
                  <div class="col-md-9 col-sm-9">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          v-model="payload.sekda"
                          type="radio"
                          value="1"
                          class="form-check-input"
                          name="optradiom"
                        />Ya
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          v-model="payload.sekda"
                          type="radio"
                          value="0"
                          class="form-check-input"
                          name="optradiom"
                        />Tidak
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="m-t-0 m-b-40" />
            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                  >
                    Unit
                  </label>
                  <div :class="['col-md-9', { invalid: isinvalidOpd }]">
                    <Multiselect
                      placeholder="Type to Search"
                      select-label
                      :value="payload.valueOpd"
                      track-by="value"
                      label="text"
                      :options="optionOpd"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @input="onChangeOpd"
                      @close="onTouch"
                      @search-change="unitFindOpd"
                    ></Multiselect>
                    <label class="typo__label form__label" v-show="isinvalidOpd"
                      >Must have at least one value</label
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                  >
                    Jabatan
                  </label>
                  <div :class="['col-md-9', { invalid: isinvalidInduk }]">
                    <Multiselect
                      placeholder="Type to Search"
                      select-label
                      :value="payload.valueInduk"
                      track-by="value"
                      label="text"
                      :options="optionInduk"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @input="onChangeInduk"
                      @close="onTouch"
                      @search-change="unitFinds"
                    ></Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isinvalidInduk"
                      >Must have at least one value</label
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                    >Unit</label
                  >
                  <div :class="['col-md-9', { invalid: isinvalidUnit }]">
                    <Multiselect
                      placeholder="Type to Search"
                      select-label
                      :value="payload.valueUnit"
                      track-by="value"
                      label="text"
                      :options="optionUnit"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @input="onChangeUnit"
                      @close="onTouch"
                      @search-change="unitFind"
                    ></Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isinvalidUnit"
                      >Must have at least one value</label
                    >
                  </div>
                </div>
              </div>
            </div> -->

            <hr class="m-t-0 m-b-40" />

            <template v-for="(role, index) in payload.multirole">
              <div class="row" :key="index">
                <div class="col-md-8">
                  <div class="form-group row">
                    <label
                      :class="[
                        'control-label',
                        { 'text-right': !isMobile },
                        'col-md-3',
                      ]"
                    >
                      Unit
                      <small style="color: red">*</small>
                    </label>
                    <div :class="['col-md-9', { invalid: !role.unitValue }]">
                      <treeselect
                        :multiple="false"
                        :options="optionUnit"
                        :flat="true"
                        :sort-value-by="'INDEX'"
                        :default-expand-level="1"
                        :flatten-search-results="true"
                        placeholder="Unit"
                        v-model="role.unitValue"
                      />
                      <!-- <Multiselect
                      placeholder="Type to search"
                      select-label="Enter doesn’t work here!"
                      :value="role.unitValue"
                      track-by="value"
                      label="text"
                      :multiple="false"
                      :options="optionUnit"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @open="unitFind('')"
                      @input="onChangeUnitNew(role,$event)"
                      @close="onTouch"
                      @search-change="unitFind"
                    ></Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="!role.unitValue"
                    >Must have at least one value</label> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" :key="index + '1'">
                <div class="col-md-8">
                  <div class="form-group row">
                    <label
                      :class="[
                        'control-label',
                        { 'text-right': !isMobile },
                        'col-md-3',
                      ]"
                    >
                      Jabatan
                      <small style="color: red">*</small>
                    </label>
                    <div :class="['col-md-9', { invalid: !role.jabatanValue }]">
                      <Multiselect
                        placeholder="Type to search"
                        select-label="Enter doesn’t work here!"
                        :value="role.jabatanValue"
                        track-by="value"
                        label="text"
                        :options="optionJabatan"
                        :searchable="true"
                        :allow-empty="false"
                        :hide-selected="true"
                        :max-height="150"
                        :disabled="isDisabled"
                        :block-keys="['Tab', 'Enter']"
                        @input="onChangeJabatanNew(role, $event)"
                        @close="onTouch"
                        @open="jabatanFind(role.unitValue, '')"
                        @search-change="jabatanFind(role.unitValue, $event)"
                      ></Multiselect>
                      <label
                        class="typo__label form__label"
                        v-show="!role.jabatanValue"
                        >Must have at least one value</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" :key="index + '2'">
                <div class="col-md-8">
                  <div class="form-group row">
                    <label
                      :class="[
                        'control-label',
                        { 'text-right': !isMobile },
                        'col-md-3',
                      ]"
                    >
                      Role
                      <small style="color: red">*</small>
                    </label>
                    <div
                      :class="[
                        'col-md-9',
                        { invalid: !role.groupValue.length },
                      ]"
                    >
                      <Multiselect
                        v-model="role.groupValue"
                        label="text"
                        track-by="value"
                        placeholder="Type to search"
                        open-direction="bottom"
                        :options="listGroup"
                        :multiple="true"
                        :searchable="true"
                        :loading="isLoading"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="false"
                        :options-limit="300"
                        :max-height="600"
                        :show-no-results="true"
                        :hide-selected="true"
                        @open="groupFind('')"
                        @search-change="groupFind"
                      >
                        <span slot="noResult">Oops! No Group found.</span>
                      </Multiselect>
                      <label
                        class="typo__label form__label"
                        v-show="!role.groupValue.length"
                        >Must have at least one value</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" :key="index + '5'">
                <div class="col-md-8">
                  <Input
                    v-model="role.place"
                    name="tempat"
                    type="text"
                    label="Tempat"
                  />
                </div>
              </div>
              <button
                :key="index + '7'"
                v-if="payload.multirole.length !== 1"
                @click.prevent="deleteUnitMultiple(index)"
                class="m-l-4 btn btn-danger"
                title="Hapus Unit"
              >
                Hapus Unit</button
              >&nbsp;
              <button
                :key="index + '6'"
                v-if="payload.multirole.length - 1 === index"
                @click.prevent="addUnitMultiple"
                class="m-l-4 btn btn-success"
                title="Tambah Unit"
              >
                Tambah Unit</button
              >&nbsp;
              <hr :key="index + '4'" />
            </template>
            <!-- <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                    Unit
                    <small style="color:red">*</small>
                  </label>
                  <div :class="['col-md-9',{ 'invalid': isInvalidUnit }]">
                    <Multiselect
                      placeholder="Type to search"
                      select-label="Enter doesn’t work here!"
                      :value="payload.valueUnit"
                      track-by="value"
                      label="text"
                      :multiple="true"
                      :options="optionUnit"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @open="unitFind('')"
                      @input="onChangeUnit"
                      @close="onTouch"
                      @search-change="unitFind"
                    ></Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isInvalidUnit"
                    >Must have at least one value</label>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">Jabatan</label>
                  <div :class="['col-md-9']">
                    <select
                      class="form-control custom-select"
                      v-model="payload.jabatan"
                      id="selectBoxJabatan"
                      name="selectBoxJabatan"
                      placeholder="Pilih"
                    >
                    <option
                      v-for="(data,index) in listPosition"
                      :key="index"
                      :value="data.value"
                    >{{data.text}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                    Jabatan
                    <small style="color:red">*</small>
                  </label>
                  <div :class="['col-md-9',{ 'invalid': isInvalidJabatan }]">
                    <Multiselect
                      placeholder="Type to search"
                      select-label="Enter doesn’t work here!"
                      :value="payload.valueJabatan"
                      track-by="value"
                      label="text"
                      :options="optionJabatan"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @input="onChangeJabatan"
                      @close="onTouch"
                      @open="jabatanFind('')"
                      @search-change="jabatanFind"
                    ></Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isInvalidJabatan"
                    >Must have at least one value</label>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                    Role
                    <small style="color:red">*</small>
                  </label>
                  <div :class="['col-md-9',{ 'invalid': isInvalidGroup }]">
                    <Multiselect
                      v-model="payload.group"
                      label="text"
                      track-by="value"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="listGroup"
                      :multiple="true"
                      :searchable="true"
                      :loading="isLoading"
                      :internal-search="false"
                      :clear-on-select="true"
                      :close-on-select="false"
                      :options-limit="300"
                      :max-height="600"
                      :show-no-results="true"
                      :hide-selected="true"
                      @open="groupFind('')"
                      @search-change="groupFind"
                    >
                      <span slot="noResult">Oops! No Group found.</span>
                    </Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isInvalidGroup"
                    >Must have at least one value</label>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <hr class="m-t-0 m-b-40"> -->

            <!-- <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">Eselon</label>
                  <div :class="['col-md-9']">
                    <select
                      class="form-control custom-select"
                      v-model="payload.eselon"
                      id="selectBoxEselon"
                      name="selectBoxEselon"
                      placeholder="Pilih"
                    >
                      <option
                        v-for="(data,index) in listEselon"
                        :key="index"
                        :value="data.value"
                      >{{data.text}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.noTlp"
                  name="sifat surat"
                  type="text"
                  label="No Telpon"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                    >Alamat</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <textarea
                      v-model="payload.address"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                    >Deskripsi</label
                  >
                  <div class="col-md-9 col-xs-9">
                    <textarea
                      v-model="payload.desc"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <hr class="m-t-0 m-b-40" />
            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                    >Aktif</label
                  >
                  <div class="col-md-9 col-sm-9">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          v-model="payload.isActive"
                          type="radio"
                          value="1"
                          class="form-check-input"
                          name="optradio"
                        />Ya
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          v-model="payload.isActive"
                          type="radio"
                          value="0"
                          class="form-check-input"
                          name="optradio"
                        />Tidak
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="['control-label', {'text-right': !isMobile}, 'col-md-3']"
                  >Super Admin</label>
                  <div class="col-md-9 col-sm-9">
                    <div class="checkbox">
                      <input v-model="payload.superAdmin" id="checkboxSuper" type="checkbox">
                      <label for="checkboxSuper">Ya</label>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                    >Upload Gambar</label
                  >
                  <div class="col-md-9 col-sm-9">
                    <Upload
                      v-model="payload.photos"
                      :value="payload.photos"
                      isImage
                      name="photo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- <drop-file @data="assignFilep12"></drop-file> -->
          </div>
          <hr />
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button
                      type="submit"
                      class="btn btn-success"
                      title="Simpan"
                    >
                      Submit</button
                    >&nbsp;
                    <button
                      type="button"
                      @click="goBack()"
                      class="btn btn-inverse"
                      title="Cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.user;
    },
    isInvalidJabatan() {
      return this.isTouched && this.payload.valueJabatan.length === 0;
    },
    isInvalidBahasa() {
      return this.isTouched && this.payload.valueBahasa.length === 0;
    },
    // isInvalidUnit() {
    //   return this.isTouched && this.payload.valueUnit.length === 0;
    // },
    isinvalidOpd() {
      return this.isTouchedOpd && this.payload.valueOpd.length === 0;
    },
    isinvalidInduk() {
      return this.isTouchedInduk && this.payload.valueInduk.length === 0;
    },
    isInvalidGroup() {
      return this.isTouched && this.payload.group.length === 0;
    },
    isinvalidUnit() {
      return this.isTouched && this.payload.valueUnit.length === 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  data() {
    return {
      optionBahasa: [
        { id: "id", text: "Indonesia" },
        { id: "en", text: "English" },
      ],
      payload: {
        nik: "",
        gelarDepan: "",
        namaAsli: "",
        gelarBelakang: "",
        username: "",
        email: "",
        password: "",
        jabatan: "",
        valueJabatan: [],
        noTlp: "",
        address: "",
        desc: "",
        multirole: [],
        valueBahasa: [],
        valueUnit: [],
        valueOpd: [],
        valueInduk: [],
        group: [],
        p12: "",
        superAdmin: false,
        cuti: "0",
        isActive: "1",
        isMeeting: "0",
        sekda: "0",
        photos: "",
      },
      userGroup: [],
      optionJabatan: [],
      optionUnit: [],
      optionOpd: [],
      optionInduk: [],
      myPhoto: [],
      isTouched: false,
      isLoading: false,
      listGroup: [],
      listPosition: [],
      listEselon: [],
      isDisabled: false,
    };
  },
  async mounted() {
    // var query = "";
    // this.getUserGroup();
    // this.jabatanFind(query);
    // this.unitFind(query);
    // this.groupFind(query);
    // this.positionFind(query);
    // this.eselonFind(query);
    this.initialize();
    this.unitFind();
    this.unitFinds("");
    this.unitFindOpd("");
  },

  methods: {
    assignFilep12(p12) {
      this.payload.p12 = p12;
    },
    deleteUnitMultiple(index) {
      this.payload.multirole.splice(index, 1);
    },
    addUnitMultiple() {
      this.payload.multirole.push({
        unitValue: null,
        jabatanValue: null,
        groupValue: [],
        place: null,
      });
    },
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/users/${this.$route.params.id}`).then((response) => {
            const state = {
              loaded: true,
            };
            this.$store.commit("user/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("user/onAdd");
          this.addUnitMultiple();
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      let user_group = [];
      if (Array.isArray(data.roles) && data.roles.length) {
        data.roles.forEach((value) => {
          user_group.push({ value: value.id, text: value.name });
        });
      }
      if (data.multipleUnits[0].unit_id != 0) {
        data.multipleUnits = data.multipleUnits.map((element) => {
          element = Object.assign(element, {
            unitValue: element.unit.id,
          });
          if (element.structural) {
            element = Object.assign(element, {
              jabatanValue: {
                value: element.structural ? element.structural.id : "",
                text: element.structural ? element.structural.name : "",
              },
            });
          } else {
            element = Object.assign(element, {
              jabatanValue: null,
            });
          }
          element = Object.assign(element, {
            groupValue: element.roles.map((el) => {
              return {
                text: el.name,
                value: el.id,
              };
            }),
          });
          return element;
        });
      } else {
        data.multipleUnits = [
          {
            unit: { id: null },
            roles: [],
            place: "",
            unitValue: null,
            jabatanValue: null,
            groupValue: [],
          },
        ];
      }

      this.payload = {
        valueInduk: data.jabatan,
        // valueInduk: data.multipleUnits[0].unit_id,
        valueOpd: data.unit,
        gelarDepan: data.front_title,
        gelarBelakang: data.rear_title,
        namaAsli: data.name,
        username: data.username,
        email: data.email,
        password: "",
        nip: data.nip,
        nik: data.nik,
        jabatan: data.position,
        valueJabatan: data.position,
        eselon: data.structural_position_id
          ? data.structural_position_id.toString()
          : "",
        noTlp: data.phone,
        address: data.address,
        multirole: data.multipleUnits,
        desc: data.description,
        valueBahasa: {
          id: data.lang,
          text: data.lang === "id" ? "Indonesia" : "English",
        },
        valueUnit: data.units.map((element) => {
          return {
            text: element.name,
            value: element.id,
          };
        }),
        group: user_group,
        superAdmin: data.name,
        cuti: data.is_leave.toString(),
        isActive: data.is_active.toString(),
        isMeeting: data.is_meeting.toString(),
        photos: data.avatar,
      };
    },
    onTouch() {
      this.isTouched = true;
    },
    onChangeInduk(value) {
      this.payload.valueInduk = value;
    },
    onChangeOpd(value) {
      this.payload.valueOpd = value;
    },
    onChangeJabatan(value) {
      this.payload.valueJabatan = value;
    },
    onChangeJabatanNew(role, value) {
      role.jabatanValue = value;
    },
    onChangeBahasa(value) {
      this.payload.valueBahasa = value;
    },
    onChangeUnit(value) {
      this.payload.valueUnit = value;
    },
    onChangeUnitNew(role, value) {
      role.unitValue = value;
      // this.payload.valueUnit = value;
    },
    goBack() {
      this.$store.dispatch("user/onCancel");
    },
    jabatanFind(unit, query) {
      this.isLoading = true;
      axios
        .get(`positions/listByUnit?s=${query}&unit=${unit}`)
        .then((res) => {
          this.optionJabatan = res.data.items;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.commit("auth/LOGOUT");
          }
        });
    },
    unitFind() {
      this.isLoading = true;
      axios
        .get(`/unit-group/get-list-unit-user`)
        .then((res) => {
          this.optionUnit = res.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.commit("auth/LOGOUT");
          }
        });
      // axios
      //   .get(`unit-groups/listByLevel?s=${query}&type_code=1`)
      //   .then(res => {
      //     this.optionUnit = res.data.items;
      //     this.isLoading = false;
      //   })
    },
    unitFinds(query) {
      this.isLoading = true;
      axios
        // .get(`units/list?s=${query}`)
        .get(`positions/list?s=${query}`)
        .then((res) => {
          this.optionInduk = res.data.items;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.commit("auth/LOGOUT");
          }
        });
    },
    unitFindOpd(query) {
      this.isLoading = true;
      axios
        .get(`units/list?s=${query}`)
        .then((res) => {
          this.optionOpd = res.data.items;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.commit("auth/LOGOUT");
          }
        });
    },
    groupFind(query) {
      this.isLoading = true;
      axios
        .get(`groups/list?s=${query}`)
        .then((res) => {
          this.listGroup = res.data.items;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.commit("auth/LOGOUT");
          }
        });
    },
    // positionFind(query) {
    //   this.isLoading = true;
    //   axios
    //     .get(`positions/list?s=${query}`)
    //     .then(res => {
    //       this.listPosition = res.data.items;
    //       this.isLoading = false;
    //     })
    //     .catch(err => {
    //       if (err.response.status === 401) {
    //         this.$store.commit("auth/LOGOUT");
    //       }
    //     });
    // },
    eselonFind(query) {
      this.isLoading = true;
      axios
        .get(`eselons/list?s=${query}`)
        .then((res) => {
          this.listEselon = res.data.items;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.commit("auth/LOGOUT");
          }
        });
    },
    submit() {
      // if (this.payload.valueBahasa.length === 0) {
      //   this.isTouched = true;
      // }
      if (this.payload.photos) {
        this.myPhoto = this.payload.photos.split(",");
      }
      const payloadup = {
        id: this.$route.params.id,
        parent_id: parseInt(this.payload.valueInduk.value),
        induk: parseInt(this.payload.valueOpd.value),
        name: this.payload.namaAsli,
        front_title: this.payload.gelarDepan,
        rear_title: this.payload.gelarBelakang,
        username: this.payload.username,
        email: this.payload.email,
        password: this.payload.password,
        nip: this.payload.nip,
        nik: this.payload.nik,
        // nik: this.payload.nip,
        // position_id: parseInt(this.payload.jabatan),
        position_id: parseInt(this.payload.valueJabatan.value),
        eselon_id: parseInt(this.payload.eselon),
        phone: this.payload.noTlp,
        address: this.payload.address,
        description: this.payload.desc,
        is_active: parseInt(this.payload.isActive),
        is_meeting: parseInt(this.payload.isMeeting),
        structural_position_id: parseInt(this.payload.sekda),
        lang: "id",
        unit_id: this.payload.valueUnit.map((val) => {
          return parseInt(val.value);
        }),
        user_group_id: this.payload.group,
        is_super_user: this.payload.superAdmin ? 1 : 0,
        is_leave: 0,
        photo: this.myPhoto[1],
        multirole: this.payload.multirole.map((el) => {
          return {
            unitValue: el.unitValue,
            jabatanValue: el.jabatanValue,
            groupValue: el.groupValue,
            place: el.place === null || el.place === "" ? "-" : el.place,
          };
        }),
      };

      const payload = {
        parent_id: parseInt(this.payload.valueInduk.value),
        induk: parseInt(this.payload.valueOpd.value),
        name: this.payload.namaAsli,
        front_title: this.payload.gelarDepan,
        rear_title: this.payload.gelarBelakang,
        username: this.payload.username,
        email: this.payload.email,
        password: this.payload.password,
        nip: this.payload.nip,
        nik: this.payload.nik,
        // nik: this.payload.nip,
        // position_id: parseInt(this.payload.jabatan),
        position_id: parseInt(this.payload.valueJabatan.value),
        eselon_id: parseInt(this.payload.eselon),
        phone: this.payload.noTlp,
        address: this.payload.address,
        description: this.payload.desc,
        is_active: parseInt(this.payload.isActive),
        is_meeting: parseInt(this.payload.isMeeting),
        structural_position_id: parseInt(this.payload.sekda),
        lang: "id",
        unit_id: this.payload.valueUnit.map((val) => {
          return parseInt(val.value);
        }),
        user_group_id: this.payload.group,
        is_super_user: this.payload.superAdmin ? 1 : 0,
        is_leave: 0,
        photo: this.myPhoto[1],
        multirole: this.payload.multirole.map((el) => {
          return {
            unitValue: el.unitValue,
            jabatanValue: el.jabatanValue,
            groupValue: el.groupValue,
            place: el.place === null || el.place === "" ? "-" : el.place,
          };
        }),
      };
      const payloadsave = {
        parent_id: parseInt(this.payload.valueInduk.value),
        induk: parseInt(this.payload.valueOpd.value),
        name: this.payload.namaAsli,
        front_title: this.payload.gelarDepan,
        rear_title: this.payload.gelarBelakang,
        username: this.payload.username,
        email: this.payload.email,
        password: this.payload.password,
        nip: this.payload.nip,
        nik: this.payload.nik,
        // nik: this.payload.nip,
        // position_id: parseInt(this.payload.jabatan),
        position_id: parseInt(this.payload.valueJabatan.value),
        eselon_id: parseInt(this.payload.eselon),
        phone: this.payload.noTlp,
        address: this.payload.address,
        description: this.payload.desc,
        is_active: parseInt(this.payload.isActive),
        is_meeting: parseInt(this.payload.isMeeting),
        structural_position_id: parseInt(this.payload.sekda),
        lang: "id",
        // unit_id: this.payload.valueUnit.map((val) => {
        //   return parseInt(val.value);
        // }),
        user_group_id: this.payload.group,
        is_super_user: this.payload.superAdmin ? 1 : 0,
        is_leave: 0,
        photo: this.myPhoto[1],
        // multirole: this.payload.multirole.map((el) => {
        //   return {
        //     unitValue: el.unitValue,
        //     jabatanValue: el.jabatanValue,
        //     groupValue: el.groupValue,
        //     place: el.place === null || el.place === "" ? "-" : el.place,
        //   };
        // }),
        // multirole: this.payload.multirole.map((el) => {
        //   return {
        //     unitValue: el.valueInduk,
        //     jabatanValue: el.valueOpd,
        //     groupValue:
        //       el.groupValue === null || el.groupValue === ""
        //         ? "-"
        //         : el.groupValue,
        //     place: el.place === null || el.place === "" ? "-" : el.place,
        //   };
        // }),
      };
      const data = JSON.stringify(payload);
      const datasave = JSON.stringify(payloadsave);
      let newData = new FormData();
      newData.append("data", data);
      // newData.append("p12", this.payload.p12);
      let newDatasave = new FormData();
      newDatasave.append("data", datasave);
      // newDatasave.append("p12", this.payload.p12);
      this.$validator.validateAll().then((success) => {
        if (success && payload.multirole.length) {
          if (this.editMode) {
            newData.append("_method", "PATCH");
            this.$store.dispatch("user/submitEdit", {
              data: newData,
              id: this.$route.params.id,
            });
          }
          // if (this.editMode) {
          //   this.$store.dispatch("user/submitEdit", payloadup);
          // }
          // else {
          //   this.$store.dispatch("user/submitAdd", newData);
          // }
        } else {
          this.$store.dispatch("user/submitAdd", newDatasave);
          // window.scrollTo(0, 0);
        }
      });
    },
    getLang(id) {
      return this.$store.state.groups.lang[id];
    },
    getUserGroup() {
      axios
        .get(`/groups`, {
          params: {
            search: "",
            page: 1,
            limit: 100,
            sortable: "asc",
          },
        })
        .then((response) => {
          response = response.data;
          this.userGroup = response.items;
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>
